import React from 'react';
import '../BookingFrom/Form.css';

function loginoff() {
    document.getElementById("login").style.display = "none";
}


function regestrierenon() {
    loginoff();
    document.getElementById("regestrieren").style.display = "flex";
}



export default class Login extends React.Component {

    state = {
        username: '',
        password: '',
    };

    /* schreibt die Eingabe in ein Formularfeld in State*/
    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };


    /* Das hier passiert wenn der User auf Best�tigen dr�ckt*/
    onSubmit = e => {
        e.preventDefault();         /* das verhindert das die Daten in der URL Leiste stehen */
        console.log(this.state);    /* die Daten aus dem State werden an die Console �bergeben */ 
    };



    render() {
        return (
        <div >
            <form className="input-container">

                <h2>Log In</h2>

                <input className="form__field" name="username" placeholder="Username" value={this.state.username} onChange={e => this.change(e)} id='name' />
                <br />
                <input className="form__field" name="password" type="password" placeholder="Password" value={this.state.password} onChange={e => this.change(e)} />
                <br />

                <div id="login-grid">
                    <span onClick={regestrierenon}>Anmelden</span>
                    <button id="ud_send_grid" onClick={e => this.onSubmit(e)}> Einloggen </button>
                </div>
                
            </form>

            
        </div >
        );
    }
}
               /* <input type="text" name="username "placeholder="E Mail" onChange={this.handlChange('username')} defaultValue={this.values.username} required />
                <input type="password" name="password" placeholder="Passwort" onChange={this.handlChange('password')} defaultValue={this.values.password} required />
               
               */