import React, { Component } from 'react';
import './Form.css'



export class FormUserDetails extends Component {

    vomflughafenoff = e => {
        document.getElementById("vomflughafen").style.display = "none";
    }

    continue = e => {                  // das ist die Funkton des Buttons
        e.preventDefault();      
        this.props.nextStep();
    }
   

    render() {
        // ladet die Values und handleChange in die props
        const { values, handleChange } = this.props; 


        return (
            
                <form>

                    <h1>Kontakdaten</h1>

                    <input type="text" placeholder="Vorname" onChange={handleChange('firstName')} defaultValue={values.firstName} className="inlineForm" required />
                    <input type="text" placeholder="Nachname" onChange={handleChange('lastName')} defaultValue={values.lastName} className="inlineForm" required />                
                    <input type="tel" placeholder="Mobiltelefon" onChange={handleChange('phone')} defaultValue={values.phone} />
                    <input type="email" placeholder="E Mail" onChange={handleChange('email')} defaultValue={values.email} />


                    <input type="submit" className="ud_send" value="Weiter" onClick={this.continue} />
                </form>
            
            )
    }
}

export default FormUserDetails;