import React from 'react';

import Reg1 from './Reg1';
import Reg2 from './Reg2';

export class Registrieren extends React.Component {

    state = {
        step: 1,
        company: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        zip: '',
        phone: '',
        email: '',
        info: '',
        password: '',
        password2: '',
    };



    /* Das hier passiert wenn der User auf Best�tigen dr�ckt*/
    onSubmit = e => {
        e.preventDefault();         /* das verhindert das die Daten in der URL Leiste stehen */
        console.log(this.state);    /* die Daten aus dem State werden an die Console �bergeben */
    };

    //Proceed to next step
    nextStep = () => {                  // Arrofunction
        const { step } = this.state;    // holt STEP aus dem state
        this.setState({                 // ver�ndere state von STEP um eines
            step: step + 1
        });
    };

    //Go back to prev step
    prevStep = () => {                  // Arrofunction
        const { step } = this.state;    // holt STEP aus dem state
        this.setState({                 // ver�ndere state von STEP um eines
            step: step - 1
        });
    };

    // Handle fields change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    };

    render() {
       // lade die Daten aus dem State
        var {step} = this.state;     // statt const hab ich hier eine globale Variable
        var { company, firstName, lastName, street, city, zip, phone, email, info, password, password2 } = this.state;
        var values = { company, firstName, lastName, street, city, zip, phone, email, info, password, password2 } = this.state;
            
        switch (step) {
            case 1:
                    return (
                    /* hier wird Step1 aufgerufen aus einen anderen Datei ...
                     * inkl. funktion nextStep....
                     * inkl. aller values aus dem State... */
                    <Reg1          
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values}
                    />
                    )
            case 2:
                    return (
                    <Reg2           
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                    />
                    )
        }    
    }
}
export default Registrieren;
              