import React, { Component } from 'react';



export default class Reg1 extends Component {

    regestrierenoff() {
    document.getElementById("regestrieren").style.display = "none";
    }


	continue = e => {                  // das ist die Funkton des Buttons
		e.preventDefault();
		this.props.nextStep();
	}


	render() {
		// ladet die Values und handleChange in die props
		const { values, handleChange } = this.props;


		return (
            <div>
                <form className="input-container">
                    <h2>Log In</h2>


                    <input type="text" placeholder="Company Name" onChange={handleChange('company')} defaultValue={values.company} required />
                    <input type="text" placeholder="First Name" onChange={handleChange('firstName')} defaultValue={values.firstName} className="inlineForm" required />
                    <input type="text" placeholder="Last Name" onChange={handleChange('lastName')} defaultValue={values.lastName} className="inlineForm" required />
                    <input type="number" placeholder="Mobile Phone" onChange={handleChange('phone')} defaultValue={values.phone} className="inlineForm" required />
                    <input type="email" placeholder="E - Mail" onChange={handleChange('email')} defaultValue={values.email} className="inlineForm" required />



                    
                    <input type="submit" className="ud_send" value="Continue" onClick={this.continue} />
                    

                </form>
            </div>
            )
    }
}

