import React from 'react';

import './Toolbar.css';

import wurfel from '../Image/wurfelori.JPG';
import logotext from '../Image/logotextschwarz.png';

import LoginIcon from '../Image/login_black.png'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

import { loginon } from '../../App'



const toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar_navigation">

            {/* Das wird das Logo */}
            <div className="toolbar_logo">
                <img src={wurfel} alt="Logo" id="wurfel_sm" />
                <img src={logotext} alt="Logo" id="logotext_sm" />
            </div>
            
            {/* Das wird das Men� */}
            <div className="toolbar_navigation-items">
                <ul>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#bestellen">Bestellen</a></li>
                    <li><a href="#flotte">Flotte</a></li>
                    <li><a href="#kontackt">Kontackt</a></li>
                </ul>
            </div>

            {/* Das ist der abstandhalter zw. Logo und Links */}
            <div className="spacer" />

            {/* Das ist der Login Button*/}
            <div className="login_button">
                <img src={LoginIcon} onClick={loginon} />
            </div>

           

            {/* Das wird der Men�button 
              * beim Klick werden die props von App.js �bergeben => onClick in der DrawerToggleButton.js */}
            <div className="toggleButton">
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            
        </nav>
    </header>
);


export default toolbar;