import React, { Component } from 'react';




export default class Reg2 extends Component {


    continue = e => {                  // das ist die Funkton des Buttons
        e.preventDefault();
        this.props.nextStep();
    }
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        // ladet die Values und handleChange in die props
        const { values, handleChange } = this.props;


        return (
            
                <form className="input-container">
                    <h2>Log In</h2>


                    <input type="text" placeholder="Street [Number]" onChange={handleChange('street')} defaultValue={values.street} required />
                    <input type="text" placeholder="City" onChange={handleChange('city')} defaultValue={values.city} className="inlineForm" required />
                    <input type="text" placeholder="Zip Code" onChange={handleChange('zip')} defaultValue={values.zip} className="inlineForm" required />
                    <input type="password" placeholder="Password" onChange={handleChange('password')} defaultValue={values.password} className="inlineForm" required />
                    <input type="password" placeholder="Password repeat" onChange={handleChange('password2')} defaultValue={values.password2} className="inlineForm" required />

                    <input type="submit" className="ud_send" value="Back" onClick={this.back} />
                    <input type="submit" className="ud_send" value="Submit" />
                    

                </form>
            
        )
    }
}

