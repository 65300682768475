import React, { Component } from 'react';
import './Form.css'

export class Confirm extends Component {

    continue = e => {
        e.preventDefault();
        // hier m�sste ich die daten an das BACKEND weiterleiten
        this.props.nextStep();
    }
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }


    render() {
        // ladet die Eingaben in eine Variable um sie hier zu verwenden
        const { values: { firstName, lastName, phone, email, date, time, flightnr, pax, street, city, zip } } = this.props;


        return (
            <form>
                <h1>Zusammenfassung </h1>
                
                <p>Name: {firstName} {lastName}</p>                
                <p>Telefon: {phone}</p>
                <p>E Mail: {email}</p>
                <p> Abholort: </p>
                <p>{street}, {zip}{city}</p>
                <p> Zielort: </p>
                <p> Flughafen Wien, 1300 Schwechat: </p>
                {pax}{date}{time}{flightnr}
                <br />

                <input type="submit" className="ud_send" value="Zur&uuml;ck" onClick={this.back} />
                <input type="submit" className="ud_send" value="Weiter" onClick={this.continue} />

            </form>
        )
    }
}



export default Confirm;
