import React, { Component } from 'react';
import './Form.css'


export class FormFromAirport extends Component {

    continue = e => {                  
        e.preventDefault();
        this.props.nextStep();
    }
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }


    render() {
        // ladet die Values und handleChange in die props
        const { values, handleChange } = this.props;
        
        return (
            <div>
                <form>
                    
                    <h1>Vom Flughafen</h1>

                    <input type="date" placeholder="25.09.2021" onChange={handleChange('date')} defaultValue={values.date} className="inlineForm" required />
                    <input type="time" placeholder="15:00" onChange={handleChange('time')} defaultValue={values.time} className="inlineForm" required />
                    <input type="text" placeholder="OS 1545" onChange={handleChange('flightnr')} defaultValue={values.flightnr} className="inlineForm" required />
                    <select placeholder="Pax" onChange={handleChange('pax')} defaultValue={values.pax} className="inlineForm" required>
                        <option selected value="pax">Pax</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                    </select>
                    
                    <input type="text" placeholder="Strasse mit Hausnummer" onChange={handleChange('street')} defaultValue={values.street}  required />
                    <input type="text" placeholder="Stadt" onChange={handleChange('city')} defaultValue={values.city} className="inlineForm" required />
                    <input type="text" placeholder="PLZ" onChange={handleChange('zip')} defaultValue={values.zip} className="inlineForm" required />

                    <input type="submit" className="ud_send" value="Zur&uuml;ck" onClick={this.back} />
                    <input type="submit" className="ud_send" value="Weiter" onClick={this.continue} />
                    
                </form>
            </div>
        )
    }
}

export default FormFromAirport;