import React, { Component } from 'react';
import './Form.css'



export class Success extends Component {

  


    render() {
        

        return (
          
                    <div>
                    <br />
                    <h1> Vielen Dank f�r Ihre Buchung</h1>
                    <p>Sie werden eine E Mail mit allen Informationen bekommen.</p>
                    <p>Danke das Sie ein �sterreichisches Einzelunternehmen unterst�tzen!</p>
                    </div>
                  
             
        )
    }
}



export default Success;
