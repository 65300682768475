import React, { Component } from 'react';

import styled from 'styled-components';
import Slider from 'react-slick';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import './Slide.css';
import '../BookingFrom/Form.css';

import Logo from '../Logo/Logo';
import UserForm from '../BookingFrom/UserForm';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    dots: false,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 1,
    centerPadding: "0px",
    slidesToScroll: 1
};

export default class HomeSlider extends Component {
	render(){
		return(
            <div id="sliderbox">
                <Slider {...settings} >

                    <div id="page1">
                        <Logo />
                    </div>

                    <section id="page2">
                        <UserForm />
                    </section>

                    <div id="page1">
                        <Logo />
                    </div>

                    <section id="page3">
                        <UserForm />
                    </section>

                </Slider>
            </div>
		);
	}
}