import React from 'react';
import './SideDrawer.css';



const sideDrawer = props => {
    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open'     /* wenn props.show aktiv ist dann import zwei Klassen von css */
    }

    return (
        <nav className={drawerClasses}>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#bestellen">Bestellen</a></li>
                <li><a href="#flotte">Flotte</a></li>
                <li><a href="#kontackt">Kontackt</a></li>
            </ul>
        </nav>
    );
};

export default sideDrawer;