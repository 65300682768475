import React, { Component } from 'react';
import './App.css';




import Login from './component/Login/Login';
import Registrieren from './component/Login/Registrieren';

import HomeSlider from './component/Slider/HomeSlider';

import Toolbar from './component/Toolbar/Toolbar';
import SideDrawer from './component/SideDrawer/SideDrawer';
import Backdrop from './component/Backdrop/Backdrop';

import UserForm from './component/BookingFrom/UserForm';




export function loginon() {
    document.getElementById("login").style.display = "flex";
}
export function loginoff() {
    document.getElementById("login").style.display = "none";
}
export function regestrierenon() {
    document.getElementById("regestrieren").style.display = "flex";
}
export function regestrierenoff() {
    document.getElementById("regestrieren").style.display = "none";
}



class App extends Component {

    state = {
        sideDrawerOpen: false
    };


    drawerToggleClickHandler = () => {    /* wenn es vorher offen war dann schlie�e es und umgekehrt */
        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDraweOpen };
        });
    };

    backdropClickHandler = () => {                 /* wenn das Men� offen ist und man klickt auf das abgedunkelte passiert folgendes */
        this.setState({ sideDrawerOpen: false });  /* das Men� wird geschlossen */
    };




    render() {

        
        let backdrop;
        if (this.state.sideDrawerOpen) {
            backdrop = <Backdrop click={this.backdropClickHandler} />;
        }



        return (

            <div className="App">



                {/* ====================================== LOGIN ============================================= */}
                <div id="login">
                    <Login />
                    <h5 onClick={loginoff}>Close</h5>
                </div>



                {/* =================================== Regestrieren ========================================= */}
                <div id="regestrieren">
                    <Registrieren />
                    <h5 onClick={regestrierenoff}>Close</h5>
                </div>



                {/* ==================================== Hauptseite ========================================== */}
                <main style={{ marginTop: '0px' }}>
                    <div className="smooth">
                       

                    <section id="home">
                        <HomeSlider />
                    </section>


                    <section id="bestellen">   
                        <UserForm />
                    </section>


                    <section id="flotte">
                        <h2>Flotte</h2>
                    </section>


                    <section id="kontackt">
                        <h2>Kontackt</h2>
                    </section>

                           
                    </div>

                    

                </main>

                {/* in der Toolbar befindet sich der Button darum �bergebe ich hier den ClickHandler */}
                <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />

                {/*  */}
                <SideDrawer show={this.state.sideDrawerOpen} />
                {backdrop}                
                
            </div>
        );
    }  
}

export default App;
