import React from 'react';
import './Logo.css';
import wurfel from '../Image/wurfelori.JPG';
import logotext from '../Image/logotextweiss.png';

function Logo() {
    
    return (
        <div id="logodiv">
            <img src={wurfel} alt="Logo" id="wurfel"/>
            <img src={logotext} alt="Logo" id="logotext"/>
        </div>
    );
}
export default Logo;